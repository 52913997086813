<template>
  <div>
    <apexchart
      type="bar"
      height="80"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    title: {
      type: String,
      default() {
        return "Charge Session";
      },
    },
    item: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    series() {
      return [
        {
          name: this.title,
          data: this.item.map((r) => ({
            x: `${this.$options.filters.formatDate(r.start_date, "DD-MMM")} / 
            ${this.$options.filters.formatDate(r.end_date, "DD-MMM")}`,
            y: r.session_count,
          })),
        },
      ];
    },
    chartOptions() {
      return {
        chart: {
          type: "bar",

          toolbar: {
            show: false, // Hides download/export options
          },
          sparkline: {
            enabled: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          type: "category",
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            show: false, // Hides y-axis labels
          },
        },
        grid: {
          show: false,
        },
        // tooltip: {
        //   enabled: true,
        //   y: {
        //     formatter: function (val) {
        //       return val + " Sessions"; // Custom format
        //     },
        //   },
        // },
      };
    },
  },
};
</script>
